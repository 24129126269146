import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import api from '../../services/api'

import '../Main/Main.css'
import CorpoPedidounico from '../../components/PedidoUnico/pedidoUnicoPublico'
function MainDashboard() {
  const [body, setBody] = useState([])
  const { search } = useLocation()
  const { id } = queryString.parse(search)
  useEffect(() => {
    ;(async () => {
      api
        .get('/pedido/publico?id=' + id)
        .then((response) => {
          const result = response.data
          setBody(result)
        })
        .catch((response) => {
          console.log(response)
        })
    })()
  }, [])

  return (
    <div>
      <br />
      <div>
        <br />
        {body.map((body) => {
          return <CorpoPedidounico key={body.id} data={body} />
        })}
      </div>
    </div>
  )
}

export default MainDashboard
