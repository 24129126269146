import { useEffect, useState } from 'react'
import api from '../../services/api'
import { Table, Button, Form } from 'react-bootstrap'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Alert from '@material-ui/lab/Alert'

import '../Main/Main.css'
import NavBar from '../../components/NavBar/NavBar'
import history from '../../history'

import ToastAnimated, { showToast } from '../../ui-lib/toast'
import 'react-toastify/dist/ReactToastify.css'

function MainDashboard() {
  const [body, setBody] = useState([])

  const [modelo, setModelo] = useState('')
  const [quilha, setQuilha] = useState('')
  const [rabeta, setRabeta] = useState('')
  const [acabamento, setAcabamento] = useState('')
  const [laminacao, setLaminacao] = useState('')

  const [getModelo, setGetModelo] = useState([])
  const [getQuilha, setgetQuilha] = useState([])
  const [getRabeta, setGetRabeta] = useState([])
  const [getAcabamento, setGetAcabamento] = useState([])
  const [getLaminacao, setGetLaminacao] = useState([])

  const refreshPage = () => {
    window.location.reload()
  }

  useEffect(() => {
    ;(async () => {
      api.get('/pedidos').then((response) => {
        const respost = response.data
        setBody(respost)
      })
    })()
  }, [])

  async function handleIsertModelo() {
    await api
      .post('/itens', {
        id: 0,
        modelo: modelo,
        quilha: null,
        rabeta: null,
        acabamento: null,
      })
      .then(() => {
        showToast({ type: 'success', message: 'item registrado com sucesso' })
      })
      .catch((response) => {
        showToast({
          type: 'error',
          message:
            'item não registrado, verifique com o administrador do sistema',
        })
      })
  }
  async function handleIsertQuilha() {
    await api
      .post('/itens', {
        id: 0,
        modelo: null,
        quilha: quilha,
        rabeta: null,
        acabamento: null,
      })
      .then(() => {
        showToast({ type: 'success', message: 'item registrado com sucesso' })
      })
      .catch((response) => {
        showToast({
          type: 'error',
          message:
            'item não registrado, verifique com o administrador do sistema',
        })
      })
  }
  async function handleIsertRabeta() {
    await api
      .post('/itens', {
        id: 0,
        modelo: null,
        quilha: null,
        rabeta: rabeta,
        acabamento: null,
      })
      .then(() => {
        showToast({ type: 'success', message: 'item registrado com sucesso' })
      })
      .catch((response) => {
        showToast({
          type: 'error',
          message:
            'item não registrado, verifique com o administrador do sistema',
        })
      })
  }
  async function handleIsertAcabamento() {
    await api
      .post('/itens', {
        id: 0,
        modelo: null,
        quilha: null,
        rabeta: null,
        acabamento: acabamento,
      })
      .then(() => {
        showToast({ type: 'success', message: 'item registrado com sucesso' })
      })
      .catch((response) => {
        showToast({
          type: 'error',
          message:
            'item não registrado, verifique com o administrador do sistema',
        })
      })
  }
  async function handleIsertLaminacao() {
    await api
      .post('/itens', {
        id: 0,
        modelo: null,
        quilha: null,
        rabeta: null,
        acabamento: null,
        laminacao: laminacao,
      })
      .then(() => {
        showToast({ type: 'success', message: 'item registrado com sucesso' })
      })
      .catch((response) => {
        showToast({
          type: 'error',
          message:
            'item não registrado, verifique com o administrador do sistema',
        })
      })
  }
  useEffect(() => {
    ;(async () => {
      api.get('itens/show?item=modelo').then((response) => {
        const result = response.data
        setGetModelo(result)
      })
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      api.get('itens/show?item=quilha').then((response) => {
        const result = response.data
        setgetQuilha(result)
      })
    })()
  }, [])
  useEffect(() => {
    ;(async () => {
      api.get('itens/show?item=rabeta').then((response) => {
        const result = response.data
        setGetRabeta(result)
      })
    })()
  }, [])
  useEffect(() => {
    ;(async () => {
      api.get('itens/show?item=acabamento').then((response) => {
        const result = response.data
        setGetAcabamento(result)
      })
    })()
  }, [])
  useEffect(() => {
    ;(async () => {
      api.get('itens/show?item=laminacao').then((response) => {
        const result = response.data
        setGetLaminacao(result)
      })
    })()
  }, [])
  return (
    <div>
      <NavBar />
      <ToastAnimated />
      <br />
      <br />
      <div>
        <Container component={Paper} elevation={6}>
          <br />
          <Alert severity="error" color="warning">
            Cadastrar um novo item?
          </Alert>
          <br />
          <div className="Formulario-pedido">
            <Form>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Container component={Paper} elevation={1}>
                  <Form.Label>Modelo</Form.Label>
                  <Form.Label>Vizualizar os modelos cadastrados</Form.Label>
                  <Form.Control as="select">
                    {getModelo.map((getModelo) => {
                      return <option>{getModelo.modelo}</option>
                    })}
                  </Form.Control>
                  <br />
                  <Form.Control
                    value={modelo}
                    onChange={(event) => {
                      setModelo(event.target.value)
                    }}
                    type="text"
                    placeholder="Modelo"
                  />
                  <br />
                  <Button onClick={handleIsertModelo}>Registrar Modelo</Button>
                  <br />
                  <br />
                  <Form.Label>Quilha</Form.Label>
                  <Form.Label>Vizualizar as quilhas cadastradas</Form.Label>
                  <Form.Control as="select">
                    {getQuilha.map((getQuilha) => {
                      return <option>{getQuilha.quilha}</option>
                    })}
                  </Form.Control>
                  <br />
                  <Form.Control
                    value={quilha}
                    onChange={(event) => {
                      setQuilha(event.target.value)
                    }}
                    type="text"
                    placeholder="Quilha"
                  />
                  <br />
                  <Button onClick={handleIsertQuilha}>Registrar Quilha</Button>
                  <br />
                  <br />
                  <Form.Label>Rabeta</Form.Label>
                  <Form.Label>Vizualizar as rabetas cadastradas</Form.Label>
                  <Form.Control as="select">
                    {getRabeta.map((getRabeta) => {
                      return <option>{getRabeta.rabeta}</option>
                    })}
                  </Form.Control>
                  <br />
                  <Form.Control
                    value={rabeta}
                    onChange={(event) => {
                      setRabeta(event.target.value)
                    }}
                    type="text"
                    placeholder="Rabeta"
                  />
                  <br />
                  <Button onClick={handleIsertRabeta}>Registrar Rabeta</Button>
                  <br />
                  <br />
                  <Form.Label>Acabamento</Form.Label>
                  <Form.Label>Vizualizar as laminações cadastradas</Form.Label>
                  <Form.Control as="select">
                    {getAcabamento.map((getAcabamento) => {
                      return <option>{getAcabamento.acabamento}</option>
                    })}
                  </Form.Control>
                  <br />
                  <Form.Control
                    value={acabamento}
                    onChange={(event) => {
                      setAcabamento(event.target.value)
                    }}
                    type="text"
                    placeholder="Acabamento"
                  />
                  <br />
                  <Button onClick={handleIsertAcabamento}>
                    Registrar acabamento
                  </Button>
                  <br />
                  <br />
                  <Form.Label>Vizualizar as laminações cadastradas</Form.Label>
                  <Form.Control as="select">
                    {getLaminacao.map((getLaminacao) => {
                      return <option>{getLaminacao.laminacao}</option>
                    })}
                  </Form.Control>
                  <br />
                  <Form.Label>Inserir uma nova laminação</Form.Label>
                  <Form.Control
                    value={laminacao}
                    onChange={(event) => {
                      setLaminacao(event.target.value)
                    }}
                    type="text"
                    placeholder="Laminação"
                  />
                  <br />
                  <Button onClick={handleIsertLaminacao}>
                    Registrar Laminacao
                  </Button>
                  <br />
                  <br />
                </Container>
              </Form.Group>
            </Form>
          </div>
          <br />
        </Container>
        <br />
        <br />
        <Container component={Paper} elevation={6}>
          <br />
          <Alert severity="success" color="info">
            Esses são os ultimos 10 pedidos
          </Alert>
          <br />
          <Table striped hover variant="dark" responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Data Entrada</th>
              </tr>
            </thead>
            {body
              .map((body) => {
                function handleClick() {
                  history.push('/Pedidos')
                  localStorage.setItem('id', body.id)
                  refreshPage()
                }
                return (
                  <tbody>
                    <tr>
                      <td onClick={handleClick}>{body.id}</td>
                      <td onClick={handleClick}>{body.name_user}</td>
                      <td onClick={handleClick}>{body.dataAber}</td>
                    </tr>
                  </tbody>
                )
              })
              .slice(0, 10)}
          </Table>
          <br />
        </Container>
      </div>
    </div>
  )
}

export default MainDashboard
