import { useEffect, useState } from 'react'
import api from '../../services/api'
import {
  Table,
  Button,
  InputGroup,
  FormControl,
  Row,
  Col,
} from 'react-bootstrap'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import DeleteIcon from '@material-ui/icons/Delete'
import Alert from '@material-ui/lab/Alert'
import ToastAnimated, { showToast } from '../../ui-lib/toast'
import 'react-toastify/dist/ReactToastify.css'
import '../Main/Main.css'
import NavBar from '../../components/NavBar/NavBar'
import history from '../../history'
const refreshPage = () => {
  window.location.reload()
}

function MainDashboard() {
  const [body, setBody] = useState([])

  const [search, setSearch] = useState('')

  useEffect(() => {
    ;(async () => {
      api.get('/pedidos').then((response) => {
        const respost = response.data
        setBody(respost)
      })
    })()
  }, [])

  const filteredPedidos = body.filter((pedidos) => {
    return pedidos.name_user.toLowerCase().includes(search.toLocaleLowerCase())
  })

  return (
    <div>
      <NavBar />
      <br />
      <br />
      <div>
        <Container component={Paper} elevation={6}>
          <Row>
            <Col>
              <br />
              <Alert severity="success" color="info">
                Esses são os ultimos 50 pedidos
              </Alert>
              <br />
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Pesquisar por nome do cliente"
                  aria-label="Pesquisar por nome do cliente"
                  value={search}
                  onChange={(event) => {
                    setSearch(event.target.value)
                  }}
                />
              </InputGroup>

              <Table striped hover responsive variant="dark">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Data Entrada</th>
                    <th></th>
                  </tr>
                </thead>
                {filteredPedidos
                  .sort((a, b) => a.dataAber.localeCompare(b.dataAber))
                  .reverse()
                  .map((body) => {
                    function handleClick() {
                      history.push('/Pedidos')
                      localStorage.setItem('id', body.id)
                      refreshPage()
                    }
                    function handleDelete() {
                      api
                        .post('pedido/delete?id=' + body.id)
                        .then(() => {
                          showToast({
                            type: 'success',
                            message: 'Pedido deletado com sucesso',
                          })
                          refreshPage()
                        })
                        .catch(() => {
                          showToast({
                            type: 'error',
                            message: 'Não foi possivel deletar o pedido',
                          })
                        })
                    }
                    return (
                      <tbody>
                        <tr>
                          <td onClick={handleClick}>{body.id}</td>
                          <td onClick={handleClick}>{body.name_user}</td>
                          <td onClick={handleClick}>{body.dataAber}</td>

                          <td>
                            <Button
                              size="sm"
                              variant="danger"
                              onClick={handleDelete}
                            >
                              {' '}
                              <DeleteIcon fontSize="small" />
                              Deletar
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    )
                  })
                  .slice(0, 50)}
              </Table>
              <br />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default MainDashboard
