import { useContext, useState } from 'react'
import { Navbar, Nav, NavLink, Form } from 'react-bootstrap'
import logo from '../../assets/logo.png'

import Chip from '@material-ui/core/Chip'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import IconButton from '@material-ui/core/IconButton'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ListIcon from '@material-ui/icons/List';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import { Context } from '../../context/AuthContext'
import history from '../../history'

import './styles.css'

function handleProfile() {
  return history.push('/Profile')
}

function NavbarCustom() {
  const { HandleLogout } = useContext(Context)
  const name = localStorage.getItem('name')
  const email = localStorage.getItem('email')
  const grupo = localStorage.getItem('grupo')


  let verify
  if (grupo === 'true') {
    verify = (
      <Nav className="mr-auto">
        <NavLink href="/Main"> <PlaylistAddCheckIcon fontSize="small" /> Pedidos</NavLink>
        <NavLink href="/Pedidos/Todos"><ListIcon fontSize="small" /> Todos os pedidos</NavLink>
        <NavLink href="/Cadastrar/itens"><BorderColorIcon fontSize="small" /> Cadastrar itens</NavLink>
        <NavLink href="/Financeiro"><AccountBalanceIcon fontSize="small" /> Financeiro</NavLink>
        <NavLink href="/Cadastrar/usuario"><PersonAddIcon fontSize="small" /> Cadastrar usuarios</NavLink>
      </Nav>
    )
  } else {
    verify = (
      <Nav className="mr-auto">
        <NavLink href="/Main"> <PlaylistAddCheckIcon fontSize="small" /> Pedidos</NavLink>
        <NavLink href="/Pedidos/Todos"><ListIcon fontSize="small" /> Todos os pedidos</NavLink>
      </Nav>
    )
  }
  return (
    <div className="navBar">
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
        fixed="top"
      >
        <Navbar.Brand href="/Main">
          <img
            alt="Tbs Surf Boards"
            src={logo}
            width="210"
            height="70"
            href="/Main"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {verify}
          <Nav>
            <Nav.Link eventKey={1}>
              <Navbar.Text>
                Logado como: {name}
              </Navbar.Text>
            </Nav.Link>
            <Nav.Link eventKey={2}>Sair
              <ExitToAppIcon onClick={HandleLogout} fontSize="small" />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <br />
    </div>
  )
}
export default NavbarCustom
