import { useEffect, useState } from 'react'
import api from '../../services/api'
import { Table, Button, CardDeck, Card } from 'react-bootstrap'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Alert from '@material-ui/lab/Alert'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'

import '../Main/Main.css'
import NavBar from '../../components/NavBar/NavBar'
import history from '../../history'

import ToastAnimated, { showToast } from '../../ui-lib/toast'
import 'react-toastify/dist/ReactToastify.css'

function MainDashboard() {
  const [body, setBody] = useState([])
  const [saldoDia, setSaldoDia] = useState('')
  const [saldoSemana, setSaldoSemana] = useState('')
  const [saldoMes, setSaldoMes] = useState('')
  const optionDia = localStorage.getItem('hideDia')
  const optionSemana = localStorage.getItem('hideSemana')
  const optionMes = localStorage.getItem('hideMes')
  const saldoDiaLocal = localStorage.getItem('saldoDiario')
  const saldoSemanalLocal = localStorage.getItem('saldoSemanal')
  const saldoMensalLocal = localStorage.getItem('saldoMensal')

  const [hideDia, sethideDia] = useState(true)
  const [hideSemana, sethideSemana] = useState(true)
  const [hideMes, sethideMes] = useState(true)
  const [saldoSemanal, setSaldoSemanal] = useState('*****')
  const [saldoMensal, setSaldoMensal] = useState('*****')
  const [saldoDiario, setSaldoDiario] = useState('*****')
  const [optionSetDia, setOptionSetDia] = useState({
    icon: <VisibilityOffIcon />,
    menssage: 'Mostrar saldo',
  })
  const [optionSetSemana, setOptionSetSemana] = useState({
    icon: <VisibilityOffIcon />,
    menssage: 'Mostrar saldo',
  })
  const [optionSetMes, setOptionSetMes] = useState({
    icon: <VisibilityOffIcon />,
    menssage: 'Mostrar saldo',
  })

  useEffect(() => {
    ;(async () => {
      api.get('/financeiro').then((response) => {
        const respost = response.data
        setBody(respost)
      })
    })()
  }, [])
  useEffect(() => {
    if (saldoDiaLocal != saldoDia) {
      api.get('/financeiro/diario').then((response) => {
        const respost = response.data
        setSaldoDia(respost)
        localStorage.setItem('saldoDiario', response.data)
      })
    }
  })

  useEffect(() => {
    if (saldoSemanal != saldoSemana) {
      api.get('/financeiro/semanal').then((response) => {
        localStorage.setItem('saldoSemanal', response.data)
      })
    }
  })

  useEffect(() => {
    if (saldoMensalLocal != saldoMes) {
      api.get('/financeiro/mensal').then((response) => {
        localStorage.setItem('saldoMensal', response.data)
      })
    }
  })

  function hideFatDiario() {
    if (hideDia === false) {
      setSaldoDiario('******')
      localStorage.setItem('hideDia', true)
      sethideDia(true)
      setOptionSetDia({
        icon: <VisibilityOffIcon />,
        menssage: 'Mostrar saldo',
      })
    } else if (hideDia === true) {
      setSaldoDiario(saldoDia)
      localStorage.setItem('hideDia', false)
      sethideDia(false)
      setOptionSetDia({
        icon: <VisibilityIcon />,
        menssage: 'Esconder saldo',
      })
    }
  }

  function hideFatSemanal() {
    if (hideSemana === false) {
      setSaldoSemanal('******')
      localStorage.setItem('hideSemana', true)
      sethideSemana(true)
      setOptionSetSemana({
        icon: <VisibilityOffIcon />,
        menssage: 'Mostrar saldo',
      })
    } else if (hideSemana === true) {
      setSaldoSemanal(saldoSemanalLocal)
      localStorage.setItem('hideSemana', false)
      sethideSemana(false)
      setOptionSetSemana({
        icon: <VisibilityIcon />,
        menssage: 'Esconder saldo',
      })
    }
  }

  function hideFatMensal() {
    if (hideMes === false) {
      setSaldoMensal('******')
      localStorage.setItem('hideMes', true)
      sethideMes(true)
      setOptionSetMes({
        icon: <VisibilityOffIcon />,
        menssage: 'Mostrar saldo',
      })
    } else if (hideMes === true) {
      setSaldoMensal(saldoMensalLocal)
      localStorage.setItem('hideMes', false)
      sethideMes(false)
      setOptionSetMes({
        icon: <VisibilityIcon />,
        menssage: 'Esconder saldo',
      })
    }
  }
  const refreshPage = () => {
    window.location.reload()
  }

  return (
    <div>
      <NavBar />
      <ToastAnimated />
      <br />
      <br />
      <div>
        <Container component={Paper} elevation={5}>
          <br />
          <CardDeck>
            <Card>
              <Card.Body>
                <Card.Title>
                  {' '}
                  <MonetizationOnIcon fontSize="large" /> Faturamento diário{' '}
                </Card.Title>
                <Card.Text>
                  <h1>R$: {saldoDiario}</h1>
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">
                  {' '}
                  {optionSetDia.menssage}{' '}
                  <Button onClick={hideFatDiario} variant="ligth">
                    {optionSetDia.icon}
                  </Button>
                </small>
              </Card.Footer>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>
                  {' '}
                  <LocalAtmIcon fontSize="large" /> Faturamento semanal{' '}
                </Card.Title>
                <Card.Text>
                  <h1>R$: {saldoSemanal}</h1>
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">
                  {' '}
                  {optionSetSemana.menssage}{' '}
                  <Button onClick={hideFatSemanal} variant="ligth">
                    {optionSetSemana.icon}
                  </Button>
                </small>
              </Card.Footer>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>
                  {' '}
                  <AccountBalanceIcon fontSize="large" /> Faturamento mensal{' '}
                </Card.Title>
                <Card.Text>
                  <h1>R$: {saldoMensal}</h1>
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">
                  {' '}
                  {optionSetMes.menssage}{' '}
                  <Button onClick={hideFatMensal} variant="ligth">
                    {optionSetMes.icon}
                  </Button>
                </small>
              </Card.Footer>
            </Card>
          </CardDeck>
          <br />
        </Container>
        <br />
        <br />
        <Container component={Paper} elevation={6}>
          <br />
          <Alert severity="success" color="info" variant="filled">
            Esses são os pedidos de hoje!
          </Alert>
          <br />
          <Table striped hover variant="dark" responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Data Entrada</th>
              </tr>
            </thead>
            {body
              .map((body) => {
                function handleClick() {
                  history.push('/pedidos')
                  localStorage.setItem('id', body.id)
                  refreshPage()
                }
                return (
                  <tbody>
                    <tr>
                      <td onClick={handleClick}>{body.id}</td>
                      <td onClick={handleClick}>{body.name_user}</td>
                      <td onClick={handleClick}>{body.dataAber}</td>
                    </tr>
                  </tbody>
                )
              })
              .slice(0, 10)}
          </Table>
          <br />
        </Container>
      </div>
    </div>
  )
}

export default MainDashboard
